
    <wp-card-view [dragOutOfHandler]="canDragOutOf"
                  [dragInto]="true"
                  [cardsRemovable]="false"
                  [highlightingMode]="highlightingMode"
                  [showStatusButton]="true"
                  [orientation]="gridOrientation"
                  (onMoved)="switchToManualSorting()"
                  [showEmptyResultsBox]="true"
                  [showInfoButton]="true">
    </wp-card-view>
  