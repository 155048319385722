<div class="wp-card"
     [ngClass]="cardClasses(workPackage)">

  <div class="wp-card--highlighting"
       [ngClass]="cardHighlightingClass(workPackage)">
  </div>

  <div class="wp-card--inline-buttons">
    <a class="wp-card--inline-cancel-button -no-decoration"
       *ngIf="workPackage.isNew || showRemoveButton"
       [ngClass]="{ '-show': workPackage.isNew }"
       [title]="text.removeCard"
       (accessibleClick)="onRemoved(workPackage)">
      <op-icon icon-classes="icon icon-close"></op-icon>
    </a>

    <a class="-no-decoration"
       *ngIf="!workPackage.isNew && showInfoButton"
       [title]="text.detailsView"
       (accessibleClick)="openSplitScreen(workPackage)">
      <op-icon icon-classes="icon icon-info2"></op-icon>
    </a>
  </div>

  <edit-form [resource]="workPackage"
             [inEditMode]="workPackage.isNew"
             *ngIf="workPackage.isNew">
    <div class="wp-card--content -new">
      <editable-attribute-field [resource]="workPackage"
                                [wrapperClasses]="'work-packages--type-selector'"
                                [fieldName]="'type'"
                                class="wp-card--type">
      </editable-attribute-field>
      <editable-attribute-field [resource]="workPackage"
                                fieldName="subject"
                                class="wp-card--subject -bold">
      </editable-attribute-field>
    </div>
  </edit-form>

  <img *ngIf="this.bcfSnapshotPath(workPackage)" [src]="this.bcfSnapshotPath(workPackage)" class="wp-card--cover-image">

  <div *ngIf="!workPackage.isNew"
       class="wp-card--content">
      <span [textContent]="wpTypeAttribute(workPackage)"
            class="wp-card--type"
            [ngClass]="typeHighlightingClass(workPackage)"></span>
    <a uiSref="work-packages.show"
       [uiParams]="{workPackageId: workPackage.id}"
       class="wp-card--id"
       [ngClass]="uiStateLinkClass">
      #{{workPackage.id}}
    </a>
    <span [textContent]="wpSubject(workPackage)"
          class="wp-card--subject"></span>
    <wp-status-button *ngIf="showStatusButton"
                      [workPackage]="workPackage"
                      class="wp-card--status">
    </wp-status-button>
    <user-avatar *ngIf="workPackage.assignee"
                 [user]="workPackage.assignee"
                 data-class-list="avatar-mini"
                 class="wp-card--assignee">
    </user-avatar>
  </div>
</div>