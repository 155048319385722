<div #container
     [ngClass]="'wp-cards-container -' + orientation">
  <div *ngIf="inReference"
       class="wp-inline-create--reference-container">
    <ndc-dynamic [ndcDynamicComponent]="referenceClass"
                 [ndcDynamicInjector]="injector"
                 [ndcDynamicOutputs]="referenceOutputs">
    </ndc-dynamic>
  </div>

  <wp-single-card *ngFor="let wp of workPackages; trackBy:trackByHref"
                  [workPackage]="wp"
                  [attr.data-is-new]="wp.isNew || undefined"
                  [attr.data-work-package-id]="wp.id"
                  [attr.data-class-identifier]="cardView.classIdentifier(wp)"
                  [showInfoButton]="showInfoButton"
                  [showStatusButton]="showStatusButton"
                  [showRemoveButton]="cardsRemovable"
                  [highlightingMode]="highlightingMode"
                  [draggable]="this.canDragOutOf(wp)"
                  [orientation]="orientation"
                  (onRemove)="removeCard(wp)">
  </wp-single-card>
</div>

<div *ngIf="showEmptyResultsBox && isResultEmpty">
  <no-results [title]="text.noResults.title" [description]="text.noResults.description"></no-results>
</div>


